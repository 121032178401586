import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import heroimage from './../../img/007c07798c3f09568093b65dac735041.png';
import rightarrow from './../../img/right-arrow-svgrepo-com.svg';

const HeroSectionNew = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const query = new URLSearchParams({ search: searchText, state: selectedState }).toString();
    navigate(`/search?${query}`);
  };

  return (
    <div className="flex bg-[#FEC8D726] p-[8vw] items-center justify-center">
      <div className='w-full lg:w-1/2 px-4 flex flex-col justify-center items-center'>
        <div>
          <h1 className='text-3xl lg:text-5xl font-bold my-4'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit
          </h1>
          <p className='my-4'>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit quis fuga beatae a distinctio sapiente atque, architecto minima nesciunt, illum repudiandae? Dolore voluptatem reprehenderit
          </p>
        </div>

        <div>
          <div className='bg-white  lg:w-[35vw] rounded-lg lg:rounded-full px-10 py-2 my-4'>
            <form className='flex flex-col lg:flex-row lg:items-center justify-between' onSubmit={handleSearch}>
              <div className='flex flex-col'>
                <label htmlFor="service" className='font-bold'>Services</label>
                <input
                  id="service"
                  className='outline-none'
                  type="search"
                  placeholder='Search your service'
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="state" className='font-bold'>State</label>
                <select
                  id="state"
                  className='bg-white'
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <option value="">Select your state</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>

              <div className='justify-self-end self-center mt-3 lg:mt-0'>
                <button type="submit" className='bg-[#A32318] text-white font-bold rounded-full'>
                  <img src={rightarrow} alt="Search" className='h-12 p-2 text-white' />
                </button>
              </div>
            </form>
          </div>

          <div className='flex flex-col lg:flex-row gap-3'>

                            
                    <div className='border-[#A32318] border-[1px] rounded-full px-4 py-2 text-[#A32318] flex items-center bg-white'>
                    <label className="custom-checkbox flex items-center">
                    <input className="checked:bg-[#A32318] border-[#A32318]" type="checkbox" name="" id="" />
                    <span className="checkmark"></span>
                    <span className=''>Top Service 1</span>
                    </label>
                    </div>


                    <div className='border-[#A32318] border-[1px] rounded-full px-4 py-2 text-[#A32318] flex items-center bg-white'>
                    <label className="custom-checkbox flex items-center">
                    <input className="checked:bg-[#A32318] border-[#A32318]" type="checkbox" name="" id="" />
                    <span className="checkmark"></span>
                    <span>Top Service 2</span>
                    </label>
                    </div>

                    <div className='border-[#A32318] border-[1px] rounded-full px-4 py-2 text-[#A32318] flex items-center bg-white'>
                    <label className="custom-checkbox flex items-center">
                    <input className="checked:bg-[#A32318] border-[#A32318]" type="checkbox" name="" id="" />
                    <span className="checkmark"></span>
                    <span>Top Service 3</span>
                    </label>
                    </div>

                            

          </div>


        </div>
      </div>

      <div className='hidden lg:block'>
        <img className='' src={heroimage} alt="" />
      </div>
    </div>
  );
};

export default HeroSectionNew;
