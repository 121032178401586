import placeholder from './../../img/Placeholder.png';
let FeatureCard = ()=>{

    return (
        <>
        
        <div className='w-full lg:w-1/5'>

                <img src={placeholder} alt="" className='rounded-3xl' />
                <h2 className='font-bold text-2xl my-3 px-2'>Some Text Here</h2>
                <p className='px-2'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea eos unde minima libero numquam nostrum porro.
                </p>

        </div>
        
        </>
    )

}

export default FeatureCard;