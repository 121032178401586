import BottomBar from "../../components/bar/bottombar";
import FeatureSection from "../../components/feature-section/feature-section";
import FooterSection from "../../components/footer-section/footer-section";
import HeroSection from "../../components/herosection/herosection";
import HeroSectionNew from "../../components/herosection/herosection-new";
import LocationSection from "../../components/location-section/location-section";
import TopNavbar from "../../components/navbar/mininavbar";
import NewNavbarResponsive from "../../components/navbar/navbar-responsive";
import NewNavbar from "../../components/navbar/newnavbar";
import ProjectsSection from "../../components/projects-section/projects-section";
import TestimonialsSection from "../../components/testimonials-section/testimonials-section";

let Home = ()=>{

    return(
        <>
        
            <TopNavbar />
            <NewNavbarResponsive />
            <HeroSectionNew />
            <BottomBar />
            <FeatureSection />
            <ProjectsSection />
            <LocationSection />
            <TestimonialsSection />
            <FooterSection />
        
        </>
    )

};

export default Home;