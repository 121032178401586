import { Link } from 'react-router-dom';

import heroimage from './../../img/007c07798c3f09568093b65dac735041.png';
import rightarrow from './../../img/right-arrow-svgrepo-com.svg';
let HeroSection = ()=>{

    return (
        <>
        <div className="flex bg-[#FEC8D726] p-[8vw] items-center justify-center">


            <div className='w-1/2 px-4 flex flex-col justify-center items-center'>

                <div>

                    <h1 className='text-5xl font-bold my-4'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit
                    </h1>
                    <p className='my-4'>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit quis fuga beatae a distinctio sapiente atque, architecto minima nesciunt, illum repudiandae? Dolore voluptatem reprehenderit
                    </p>

                </div>


                <div>

                    <div className='bg-white w-[35vw] rounded-full px-10 py-2 my-4'>

                            <form className='flex items-center justify-between'>
                                <div className='flex flex-col'>
                                <label htmlFor="" className='font-bold'>Services</label>
                                <input className='outline-none' type="search" name="" id="" placeholder='Search your service'/>
                                </div>

                                <div className='flex flex-col'>
                                <label htmlFor="" className='font-bold'>State</label>
                                <select name="" id="" className='bg-white'>
                                    <option value="">Select your state</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                                </div>

                                <div className='justify-self-end'>
                                <Link to={`/search`}>
                                <button className='bg-[#A32318] text-white font-bold rounded-full '>
                                    <img src={rightarrow} alt="" className='h-12 p-2 text-white'/>
                                </button>
                                </Link>
                                </div>
                            </form>

                    </div>

                    <div className='flex gap-3'>

                            <button className='border-[#A32318] border-[1px] rounded-full px-4 py-3 text-[#A32318]'>
                                Top Service 1
                            </button>
                            
                            <button className='border-[#A32318] border-[1px] rounded-full px-4 py-3 text-[#A32318]'>
                                Top Service 2
                            </button>
                            
                            <button className='border-[#A32318] border-[1px] rounded-full px-4 py-3 text-[#A32318]'>
                                Top Service 3
                            </button>

                    </div>

                </div>

            </div>

            <div>

                <img src={heroimage} alt="" />

            </div>

        

        </div>
        
        </>
    )


};

export default HeroSection;