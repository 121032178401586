import React, { useState } from 'react';
// import './App.css';

const TopNavbar = () => {
  const [fontSize, setFontSize] = useState(16);

  const increaseFontSize = () => {
    setFontSize(prevSize => prevSize + 1);
  };

  const decreaseFontSize = () => {
    setFontSize(prevSize => (prevSize > 1 ? prevSize - 1 : prevSize));
  };

  return (
    <nav className="bg-[#A32318] h-10 text-white px-16 py-1 flex justify-between items-center">
      <div className="flex items-center space-x-4">
        
        <select
          className="bg-[#A32318] hover:bg-gray-600 px-2 py-1 rounded"
          onChange={(e) => console.log(e.target.value)}
        >
          <option className="bg-none" value="en">English</option>
          <option className="bg-none" value="es">Español</option>
          <option className="bg-none" value="fr">Français</option>
          <option className="bg-none" value="de">Deutsch</option>
          {/* Add more languages as needed */}
        </select>

        <button
          onClick={decreaseFontSize}
          className="bg-none hover:bg-gray-600 px-2 py-1 rounded"
        >
          -
        </button>
        <button
          onClick={increaseFontSize}
          className="bg-none hover:bg-gray-600 px-2 py-1 rounded"
        >
          +
        </button>


      </div>
      <a href="/login" className="bg-none hover:bg-gray-600 px-4 py-1 rounded">
        Login
      </a>
    </nav>
  );
};

export default TopNavbar;
