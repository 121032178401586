import ProjectsSlider from "../projects-slider/projects-slider";

let ProjectsSection = ()=>{

    return(
        <>
        
            <div className="flex items-center justify-center">
                <h1 className="w-[90vw] text-3xl lg:text-5xl lg:p-5 font-bold">
                    Projects
                </h1>
            </div>

            <div >

                <ProjectsSlider />

            </div>
        
        </>

    )

};

export default ProjectsSection;