import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './pages/home/home';
import SearchResult from './pages/search/search';


function App() {
  return (
    <>


      <Routes>

              <Route path='/' element={<Home />} />
              <Route path='/search' element={<SearchResult />} />
              <Route path='*' element={<Navigate to='/' replace={true} />} />

      </Routes>
      

    
    </>
  );
}

export default App;
