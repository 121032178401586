let BottomBar = ()=>{

    return (

        <>
        
        <div className="bg-black">


            <ul className="list-none flex items-center justify-between lg:text-white">
                <li className="list-none inline-block text-white font-bold lg:text-2xl text-xs lg:p-10 p-3"><a href="">Some Text Here</a></li>
                <li className="list-none inline-block text-white font-bold lg:text-2xl text-xs lg:p-10 p-3"><a href="">Some Text Here</a></li>
                <li className="list-none inline-block text-white font-bold lg:text-2xl text-xs lg:p-10 p-3"><a href="">Some Text Here</a></li>
                <li className="list-none inline-block text-white font-bold lg:text-2xl text-xs lg:p-10 p-3"><a href="">Some Text Here</a></li>
                <li className="list-none inline-block text-white font-bold lg:text-2xl text-xs lg:p-10 p-3"><a href="">Some Text Here</a></li>
                <li className="list-none inline-block text-white font-bold lg:text-2xl text-xs lg:p-10 p-3"><a href="">Some Text Here</a></li>
            </ul>



        </div>
        
        </>

    )

};

export default  BottomBar;