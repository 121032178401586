import Slider2 from "../new-testimonial-slider/slider2";
import Slider1 from "../slider1/slider1";
import backgroundImage from './../../img/5f80031f468097715bd00f41337142fb.png';


let TestimonialsSection = ()=> {

    return (
        <>
        
            <div className="py-20 bg-[#6A0027] bg-testimonial-pattern">

                    <h1 className="text-center text-white text-5xl pb-8">
                        Testimonials
                    </h1>

                    <Slider1 />








            </div>
        
        
        </>
    )


}

export default TestimonialsSection;