import { useState } from "react";
import SearchResultCard from "../../components/Search-result-card/search-result-card";
import FaqSection from "../../components/faq-section/faq-section";
import TopNavbar from "../../components/navbar/mininavbar";
import NewNavbarResponsive from "../../components/navbar/navbar-responsive";
import NewNavbar from "../../components/navbar/newnavbar";
import VideoSidebar from "../../components/video-sidebar/video-sidebar";
import rightarrow from './../../img/right-arrow-svgrepo-com.svg';
import searchIcon from './../../img/search.svg';
import starIcon from './../../img/star-2768.svg';
import TestimonialsSection from "../../components/testimonials-section/testimonials-section";
import FooterSection from "../../components/footer-section/footer-section";
import MapComponent from "../../components/map-section/new-map-section";
// import MapSection from "../../components/map-section/map-section";

let SearchResult = ()=>{


    const [searchValue, setSearchValue] = useState('Healthcare');

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
        console.log(searchValue)

      };

    

    return(

        <>
        
            <TopNavbar />
            <NewNavbarResponsive />

            <div className="flex flex-col items-center justify-center pt-10 bg-[#FEC8D726]">

                <h1 className="lg:text-5xl text-3xl font-bold">
                    Lorem ipsum dolor sit amet
                </h1>

                <p className="mt-4 px-2">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat, iure. 
                </p>

            </div>

            <div className='flex items-center justify-center px-10 py-6 bg-[#FEC8D726]'>

                            <form className='flex flex-col lg:flex-row items-center justify-between gap-3'>
                                <div className='flex flex-col border-[#A32318] border-[1px] rounded-full h-14 w-80 px-10 justify-center bg-white'>
                                <label htmlFor="" className='font-bold text-xs'>Search service</label>
                                <input className="outline-none" value={searchValue} onChange={handleInputChange} type="search" name="" id="" placeholder='Healthcare'/>
                                </div>

                                <div className='flex flex-col border-[#A32318] border-[1px] rounded-full h-14 w-80 px-10 justify-center bg-white'>
                                <label htmlFor="" className='font-bold text-xs'>Select state</label>
                                <select name="" id="" className='bg-white'>
                                    <option value="">Select your state</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                                </div>

                                <div className='justify-self-end'>
                                <button className='bg-[#A32318] text-white font-bold rounded-full '>
                                    <img src={rightarrow} alt="" className='h-12 p-2 text-white'/>
                                </button>
                                </div>
                            </form>

                    </div>

                    <div className="flex items-center justify-center bg-[#FEC8D726] pb-10">


                        <div className='flex flex-wrap justify-center gap-3'>

                            
                            <div className='border-[#A32318] border-[1px] rounded-full px-4 py-2 text-[#A32318] flex items-center bg-white'>
                            <label className="custom-checkbox flex items-center">
                            <input className="checked:bg-[#A32318] border-[#A32318]" type="checkbox" name="" id="" />
                            <span className="checkmark"></span>
                            <span>Healthcare</span>
                            </label>
                            </div>


                            <div className='border-[#A32318] border-[1px] rounded-full px-4 py-2 text-[#A32318] flex items-center bg-white'>
                            <label className="custom-checkbox flex items-center">
                            <input className="checked:bg-[#A32318] border-[#A32318]" type="checkbox" name="" id="" />
                            <span className="checkmark"></span>
                            <span>Job Assistance</span>
                            </label>
                            </div>

                            <div className='border-[#A32318] border-[1px] rounded-full px-4 py-2 text-[#A32318] flex items-center bg-white'>
                            <label className="custom-checkbox flex items-center">
                            <input className="checked:bg-[#A32318] border-[#A32318]" type="checkbox" name="" id="" />
                            <span className="checkmark"></span>
                            <span>Language Course</span>
                            </label>
                            </div>

                            

                        </div>


                    </div>


                    <div className="flex flex-col lg:flex-row my-10">


                            <div className="lg:w-2/3 w-full lg:px-28 px-2">

                                <div>
                                    <h1 className="lg:text-4xl text-2xl font-bold">
                                        Healthcare in Toronto ON (12646 Result(s))
                                    </h1>
                                </div>

                                <div className="flex gap-3 mt-4 items-center flex-col lg:flex-row ">
                                    <p className="font-bold">
                                        Filters
                                    </p>

                                    <div>

                                            <div className='flex flex-col border-[#A32318] border-[1px] rounded-full h-10 w-64 px-4 justify-center text-[#A32318]'>
                                            
                                            <select name="" id="" className='bg-white'>
                                                <option value="">Select Service</option>
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisiana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                            </select>
                                            </div>

                                            

                                    </div>

                                    <div className='flex border-[#A32318] text-[#A32318] border-[1px] rounded-full h-10 w-64 px-4 items-center gap-2'>

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                                       <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                                    </svg>

                                                    <input className="outline-none text-[#A32318]" type="search" value="Location" />

                                    </div>

                                    <div className='flex flex-col border-[#A32318] border-[1px] rounded-full h-10 w-64 px-4 justify-center text-[#A32318]'>
                                            
                                            <select name="" id="" className='bg-white'>
                                                <option value="">Rating</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                
                                            </select>
                                            </div>

                                            <div className='flex flex-col border-[#A32318] border-[1px] rounded-full h-10 w-64 px-4 justify-center text-[#A32318]'>
                                            
                                            <select name="" id="" className='bg-white'>
                                                <option value="">Sort By</option>
                                                <option value="LANGUAGE">Language</option>
                                                <option value="STATES">States</option>
                                                
                                            </select>
                                            </div>




                                </div>


                                <div className="">

                                    <SearchResultCard />
                                    <SearchResultCard />
                                    <SearchResultCard />


                                </div>

                            </div>

                            <div className="lg:w-1/3 w-full p-2 lg:pe-28 flex flex-col gap-5">

                                <FaqSection />

                                <VideoSidebar />

                                {/* <MapSection /> */}

                                <MapComponent />

                            </div>


                    </div>

                    <TestimonialsSection />

                    <FooterSection />
            
        
        </>


    );

};

export default SearchResult;