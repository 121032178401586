import placeholderImage from './../../img/video-placeholder.png';

let VideoCard = ()=>{

    return(

        <>
        
                <div className='lg:max-w-[30vw] w-full flex gap-2 items-center'>

                        <div className="w-1/4">

                            <img className='rounded-xl aspect-square' src={placeholderImage} alt="" />

                        </div>
                        
                        <div className="w-3/4">

                            <h1 className='text-xl font-bold '>
                                    Lorem ipsum dolor sit amet consectetur. 
                            </h1>

                            <p>
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas quisquam, minima exercitationem eaque sint recusandae.
                            </p>



                        </div>

                </div>

        
        </>

    )

}

export default VideoCard;