import LocationSlider from "../location-slider/location-slider";

let LocationSection = ()=>{

    return(
        <>
        
            <div className="flex items-center justify-center">
                <h1 className="w-[90vw] text-3xl lg:text-5xl lg:p-5 font-bold">
                    Locations
                </h1>
            </div>

            <div >

                <LocationSlider />

            </div>
        
        </>

    )

};

export default LocationSection;