import FeatureCard from "../feature-card/feature-card";

let FeatureSection = ()=>{

    return (
        <>
        
        <div className="flex items-center justify-center flex-col my-4 lg:my-14">

            <div className="w-[90vw]">

            

            <div className="py-3 lg:p-5">
                <h1 className="font-bold text-3xl lg:text-5xl my-2 lg:my-10">
                    Key Features

                </h1>

                <p className="text-base lg:text-2xl">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat assumenda porro maxime voluptate deserunt alias cumque aliquid, <br />eligendi dolore asperiores inventore nemo vel minus nisi distinctio, fugit commodi totam eius.
                </p>
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-center gap-8">

                <FeatureCard />
                <FeatureCard />
                <FeatureCard />
                <FeatureCard />


            </div>


            </div>
        </div>
        
        
        </>
    )


};

export default FeatureSection;