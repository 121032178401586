import { useState } from "react"


const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };
  
    return (
        <div className="mb-1 border border-gray-300 rounded-md">
          <div
            className="p-4 bg-gray-100 cursor-pointer"
            onClick={toggleOpen}
          >
            {question}
          </div>
          <div
            className={`overflow-hidden transition-all ease-in-out duration-500 ${
              isOpen ? 'max-h-screen' : 'max-h-0'
            }`}
          >
            <div className="p-4 bg-white">
              {answer}
            </div>
          </div>
        </div>
      );
  };
  
  const FAQ = () => {
    const faqs = [
        { question: 'What services do you offer?', answer: 'We offer a variety of services including web development, mobile app development, and digital marketing.' },
        { question: 'How can I request a service?', answer: 'You can request a service by contacting us through our website or by calling our customer service number.' },
        { question: 'What is the turnaround time for a project?', answer: 'The turnaround time varies depending on the complexity of the project, but typically ranges from 2 to 6 weeks.' },
        { question: 'Do you provide customer support?', answer: 'Yes, we offer 24/7 customer support to assist you with any questions or issues you may have.' },
      ];
    return (
      <div className="max-w-3xl mx-auto mt-8">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    );
  };

let FaqSection = ()=>{

    return(

        <>
        
            <div>


                <div>
                    <h1 className="text-3xl font-bold">
                        People also Ask
                    </h1>
                </div>

                <div>

                    <FAQ />

                </div>




            </div>
        
        
        </>
    )

}

export default FaqSection;