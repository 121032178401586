'use client'

import { useState } from 'react'
import USAMap from 'react-svgmap-usa'

export default function MapComponent() {
    const [state, setState] = useState('')

    return (
        <>
            <div className='bg-gray-200 p-4 my-2 rounded-xl'>

                <h1 className='font-bold text-3xl mb-4'>
                    View by Map
                </h1>

                <div className='bg-slate-100 p-4 border-white border-8 rounded-md'>

                <USAMap
                onClick={(selectedValue) => setState(selectedValue)}
                
                mapColor='DeepSkyBlue'
                strokeColor='#4f4f4f'
                strokeWidth='1'
                hoverColor='DodgerBlue'
                />

                </div>

            </div>
            
        </>
    )
}
