import placeholder from './../../img/Placeholder.png';
import star from './../../img/star-2768.svg';
let TestimonialsCard = ()=>{

    return (
        <>
        
        <div className='w-full felx flex-col items-center justify-center rounded-xl bg-white p-4 my-10'>

            <div className='flex'>

                <div>

                    <img src={placeholder} className='w-12 h-12 rounded-full mx-1' alt="" />

                </div>

                <div className='ms-2'>

                    <div className='font-bold uppercase'>Company Name Here</div>
                    <div className='uppercase text-sm'>Title Here</div>

                </div>



            </div>


            <div>

                <hr className='mt-2' />

                <p className='mt-3'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni sed provident ratione laudantium nisi laborum labore, eos voluptas facere rem autem ea, delectus natus! Expedita ad eveniet debitis odio quidem?
                    <br />
                    <span className='flex mt-3'>
                        
                    <img src={star} alt="" className='h-4 w-4' />
                    <img src={star} alt="" className='h-4 w-4' />
                    <img src={star} alt="" className='h-4 w-4' />
                    <img src={star} alt="" className='h-4 w-4' />
                    <img src={star} alt="" className='h-4 w-4' />
                    </span>
                </p>
                
            </div>


                
        </div>
        
        </>
    )

}

export default TestimonialsCard;