import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

import logo from './../../img/6-234c6625e3.webp';

const NewNavbarResponsive = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white px-10">
      <div className="flex items-center justify-between">
        <div>
          <Link to={`/`}>
            <img src={logo} alt="" className="h-16" />
          </Link>
        </div>

        <div className="flex items-center">
          <div className="hidden md:block">
            <ul className="list-none">
              <li className="inline-block p-4 font-bold"><Link to={`/`}>Home</Link></li>
              <li className="inline-block p-4 font-bold"><a href="#">About</a></li>
              <li className="inline-block p-4 font-bold"><a href="#">Projects</a></li>
              <li className="inline-block p-4 font-bold"><a href="#">Resources</a></li>
              <li className="inline-block p-4 font-bold"><a href="#">How to help?</a></li>
            </ul>
          </div>
          <button className="bg-[#A32318] text-white font-bold rounded-full px-5 hidden md:block">
            Contact us
          </button>

          <button className="md:hidden ml-4" onClick={toggleMenu}>
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden mt-4">
          <ul className="list-none">
            <li className="block p-4 font-bold"><Link to={`/`} onClick={toggleMenu}>Home</Link></li>
            <li className="block p-4 font-bold"><a href="#" onClick={toggleMenu}>About</a></li>
            <li className="block p-4 font-bold"><a href="#" onClick={toggleMenu}>Projects</a></li>
            <li className="block p-4 font-bold"><a href="#" onClick={toggleMenu}>Resources</a></li>
            <li className="block p-4 font-bold"><a href="#" onClick={toggleMenu}>How to help?</a></li>
          </ul>
          <button className="bg-[#A32318] text-white font-bold rounded-full px-5 w-full mt-4">
            Contact us
          </button>
        </div>
      )}
    </nav>
  );
}

export default NewNavbarResponsive;
