import placeholder from './../../img/placeholder2.png';
import placeholder2 from './../../img/Placeholder.png';
let LocationCard = ()=>{

    return (
        <>
        
        <div className='p-10'>

                <img src={placeholder2} alt="" className='rounded-3xl' />
                <h2 className='font-bold text-2xl my-3 px-2'>Some Text Here</h2>
                <p className='px-2'>

                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea eos unde minima libero numquam nostrum porro.
                    
                </p>
                <a className='px-2 no-underline text-[#A32318]' href="">Read More</a>

        </div>
        
        </>
    )

}

export default LocationCard;