import VideoCard from "../video-card/video-card";

let VideoSidebar = ()=>{

    return(
        <>
        
            <div className="flex flex-col gap-5  bg-[#FEC8D726] p-4 rounded-xl">

                <div>

                    <h1 className="text-3xl font-bold">
                        Videos
                    </h1>

                </div>

                <div className="flex flex-col gap-2">

                    <VideoCard />
                    <VideoCard />

                </div>

            </div>
        
        
        </>
    );
}

export default VideoSidebar;