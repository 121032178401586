import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './location-slider.css';

// import required modules
import { Pagination } from 'swiper/modules';
import LocationCard from '../location-card/location-card';

export default function LocationSlider() {
  return (
    <>
      <Swiper
        slidesPerView={4.5}
        spaceBetween={0}
        grabCursor={true}
        // loop={true}
        // centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1.2,
            spaceBetween: 8,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 4.5,
            spaceBetween: 0,
          },
        }}
        modules={[]}
        className="mySwiper"
      >
        <SwiperSlide> <LocationCard /> </SwiperSlide>
        <SwiperSlide> <LocationCard /> </SwiperSlide>
        <SwiperSlide> <LocationCard /> </SwiperSlide>
        <SwiperSlide> <LocationCard /> </SwiperSlide>
        <SwiperSlide> <LocationCard /> </SwiperSlide>
        <SwiperSlide> <LocationCard /> </SwiperSlide>
        <SwiperSlide> <LocationCard /> </SwiperSlide>
      </Swiper>
    </>
  );
}


