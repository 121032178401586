import placeholderimage from './../../img/Placeholder.png'
import starIcon from './../../img/star-2768.svg'

let SearchResultCard = ()=>{

    return(
        <>
        
            <div className='w-[full] border-[1px] border-gray-300 rounded-3xl p-2 my-6'>

                    <div className='flex'>

                        <div className="w-1/2 hidden">

                            <img className='rounded-3xl' src={placeholderimage} alt="" />


                        </div>

                        <div className="w-full flex flex-col ms-3 gap-4 justify-center">

                            <h1 className='text-xl font-semibold'>
                                ABC Medical Center

                            </h1>

                            <span className='flex gap-2 items-center'>
                                <p>
                                    (15)
                                </p> 
                                
                                <div className='flex gap-1'>
                                    
                                    <img src={starIcon} alt="" className='h-4 w-4' />
                                    <img src={starIcon} alt="" className='h-4 w-4' />
                                    <img src={starIcon} alt="" className='h-4 w-4' />
                                    <img src={starIcon} alt="" className='h-4 w-4' />
                                    <svg className='text-[#A32318]' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16">
                                        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                                    </svg>


                                </div>

                                <a className='text-[#A32318] font-bold' href=""> Read Reviews</a>

                            </span>

                            <div className='mt-4 flex gap-2 flex-grow'>

                                <a href="">
                                    <button className='border-[#A32318] border-[1px] text-white rounded-full flex gap-2 px-4 py-2 items-center flex-grow bg-[#A32318]' title='Call'>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                    </svg>

                                    

                                    </button>
                                </a>

                                <a href="">
                                    <button className='border-[#A32318] border-[1px] text-white rounded-full flex gap-2 px-4 py-2 items-center flex-grow bg-[#A32318]' title='Directions'>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sign-intersection-y" viewBox="0 0 16 16">
                                    <path d="M10.493 5.197 8 7.483 5.507 5.197 4.493 6.303 7.25 8.83V12h1.5V8.83l2.757-2.527z"/>
                                    <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098zm-1.4.7a.495.495 0 0 1 .7 0l6.516 6.515a.495.495 0 0 1 0 .7L8.35 14.866a.495.495 0 0 1-.7 0L1.134 8.35a.495.495 0 0 1 0-.7L7.65 1.134Z"/>
                                    </svg>

                                    

                                    </button>
                                </a>

                                <a href="">
                                    <button className='border-[#A32318] border-[1px] text-white rounded-full flex gap-2 px-4 py-2 items-center flex-grow bg-[#A32318]' title='Website'>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-display" viewBox="0 0 16 16">
                                    <path d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4q0 1 .25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75Q6 13 6 12H2s-2 0-2-2zm1.398-.855a.76.76 0 0 0-.254.302A1.5 1.5 0 0 0 1 4.01V10c0 .325.078.502.145.602q.105.156.302.254a1.5 1.5 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.76.76 0 0 0 .254-.302 1.5 1.5 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.76.76 0 0 0-.302-.254A1.5 1.5 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145"/>
                                    </svg>

                                    

                                    </button>
                                </a>

                                <a href="">
                                    <button className='border-[#A32318] border-[1px] text-white rounded-full flex gap-2 px-4 py-2 items-center flex-grow bg-[#A32318]' title='Message'>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-dots" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                    <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                    </svg>

                                    

                                    </button>
                                </a>

                            </div>


                            <span className='flex gap-2 hidden'>

                                <button className='bg-[#A32318] rounded-full text-white px-4 py-2'>

                                    General Health

                                </button>
                                
                                <button className='bg-[#A32318] rounded-full text-white px-4 py-2'>

                                    Pediatrics

                                </button>

                                
                                <button className='bg-[#A32318] rounded-full text-white px-4 py-2'>

                                    Mental Health

                                </button>

                            </span>

                            <p>
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vitae, eos, dolores reprehenderit blanditiis, quasi provident ducimus iure id veniam laudantium aliquid cumque voluptate placeat explicabo suscipit illo possimus maxime sed?
                            </p>

                        </div>

                    </div>

                    

            </div>
        
        </>
    );

};

export default SearchResultCard;