import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import './slider1.css';
import ProjectCard from '../project-card/project-card';
import TestimonialsCard from '../testimonials-card/testimonials-card';

const Slider1 = () => {
  return (
    <Splide
      options={{
        type: 'loop',
        perPage: 2.9,
        perMove: 1,
        gap: '30px',
        focus: 'center',
        drag: false,
        pagination: true,
        arrows: true,
        padding: { left: '10%', right: '10%' },
        breakpoints: {
          1024: {
            perPage: 1,
            padding: { left: '10%', right: '10%' },
          },
        },
      }}
      aria-label="Projects"
    >
      <SplideSlide> <TestimonialsCard /> </SplideSlide>
      <SplideSlide> <TestimonialsCard /> </SplideSlide>
      <SplideSlide> <TestimonialsCard /> </SplideSlide>
      <SplideSlide> <TestimonialsCard /> </SplideSlide>
      <SplideSlide> <TestimonialsCard /> </SplideSlide>
      <SplideSlide> <TestimonialsCard /> </SplideSlide>
      <SplideSlide> <TestimonialsCard /> </SplideSlide>
    </Splide>
  );
};

export default Slider1;
